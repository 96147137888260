import axios from "../api/axios";
//import React from "react";
import useAuth from "./useAuth";

const useRefreshToken = () => {
  const { setAuth } = useAuth();

  const refresh = async () => {
    //{\"acc\": \"" + $.trim($('#setup-clientcode-input-win').val().toUpperCase()) + "\", \"p\": \"" + $.trim($('#setup-pin-input-win').val()) + "\"}

    const response = await axios.get("/auth/refreshToken", {
      withCredentials: true,
    });

    setAuth((prev) => {
      // console.log("prev: ", JSON.stringify(prev));
      // console.log("currentData: ", response.data);
      if (prev.userProfile) {
        return {
          ...prev,
          userProfile: { ...prev.userProfile || {} },
          accountCode: response.data.clientIdentifier,
          accessToken: response.data.accessToken,
          userData: response.data,
          driverPoiSettings: localStorage.getItem("driverPoiSettings"),
        };
      } else {
        return {
          ...prev,
          userProfile: {},
          accountCode: response.data.clientIdentifier,
          accessToken: response.data.accessToken,
          userData: response.data,
          driverPoiSettings: localStorage.getItem("driverPoiSettings"),
        };
      }
    });
    return response.data.accessToken;
  };

  return refresh;
};

export default useRefreshToken;
