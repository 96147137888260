import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { axiosPrivate } from "../api/axios";
import useAuth from "./useAuth";
import useRefreshToken from "./useRefreshToken";

const useAxiosPrivate = () => {
  let navigate = useNavigate();
  let location = useLocation();
  const refresh = useRefreshToken();
  const { auth } = useAuth();
  
  useEffect(() => {    
    const requestIntercept = axiosPrivate.interceptors.request.use(
      (config) => {
        if (!config.headers["Authorization"]) {
          //1st attempt
          config.headers["Authorization"] = "Bearer " + auth.accessToken;
        }
        if (config.method === "patch") {
          config.headers["Content-Type"] = "application/json-patch+json"; //{ "Content-Type": "application/json-patch+json" }
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    const responseIntercept = axiosPrivate.interceptors.response.use(
      (response) => response,
      async (error) => {
        const prevRequest = error.config;
        if (
          error.response &&
          error.response.status === 403 &&
          !prevRequest.sent
        ) {
          prevRequest.sent = true;
          const newAccessToken = await refresh();
          prevRequest.headers["Authorization"] = "Bearer " + newAccessToken;
          return axiosPrivate(prevRequest);
        } else if (error.response && error.response.status === 401) {
          navigate("/login", { state: { from: location }, replace: true });
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axiosPrivate.interceptors.request.eject(requestIntercept);
      axiosPrivate.interceptors.response.eject(responseIntercept);
    };
  }, [auth, refresh,navigate,location]);

  return axiosPrivate;
};

export default useAxiosPrivate;
