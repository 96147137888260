import React, { useEffect, useState, useRef } from "react";
import "./App.css";
import ScreenLoading from "./components/screen-loading/screenLoading";
import fingerprint2 from "./fingerprint2";
import MainRouter from "./routing/mainRouter";
import networkKeys from "./networkKeys.json";
import { Alert, AlertTitle, Button, Snackbar } from "@mui/material";
import DriverAlarmsComp from "./components/driver-alarms/driverAlarms";
import useAuth from "./hooks/useAuth";
//import { ServiceWorkerUpdateListener } from './ServiceWorkerUpdateListener.js';
import { ContentSteeringController } from "hls.js";

function App() {
  const [loading, setLoading] = useState(true);
  const [networkAccess, setNetworkAccess] = useState(true);
  const { auth } = useAuth();
  const [updateWaiting, setUpdateWaiting] = useState(false);  
  //const [swListener, setSwListener] = useState({});  
  const spinner = document.getElementById("preloadSpinner");
  const registrationRef = useRef(null);
  const swIntervalIdRef = useRef(null);
  const UpdateWaiting = ({ updateWaiting, handleUpdate }) =>
  {
    if (!updateWaiting) return <></>
    handleUpdate();
    return (
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={true}
        // onClose={() =>
        // {
        //   setNetworkAccess(false);
        // }}
        sx={{ boxShadow: "0 0 12px rgb(0 0 0 / 70%)" }}
      >
        <Alert
          sx={{ width: "100%" }}
          severity="warning"
          variant="filled"
          action={
            <Button
              variant="outlined"
              onClick={() => { window.location.reload(); }}
              color="inherit"
              size="small"
            >
              Update App
            </Button>
          }
        >
          <AlertTitle>
            New version of the app is availabe, Click to update the app.
          </AlertTitle>
        </Alert>
      </Snackbar>
    )
  }
  if (spinner) {
    setTimeout(() => {
      spinner.style.display = "none";
      setLoading(false);
    }, 1000 * 3); //10 seconds
  } 
  const onNetEvent = () => {
    var condition = navigator.onLine ? "online" : "offline";
    let networkState = "";
    if (window.navigator.hasOwnProperty("connection")) {
      networkState = window.navigator.connection.effectiveType;
    } else {
      networkState = "online";
    }

    if (condition == "online") {
      // $("#setup-network-state").text(networkKeys[networkState]); // this is for setting page, to see internet connection
      if (networkKeys[networkState] === "No network connection") {
        setNetworkAccess(false);        
      } else {
        setNetworkAccess(true);
      }
    } else {
      setNetworkAccess(false);
    }
    // checkIC();
  };

  // useEffect(() =>  {
  //   console.log("swListener changed effect:" + JSON.stringify(swListener),swListener);
  //   if (swListener) {     
  //     if (swListener.waiting || swListener.installed) {
  //       setUpdateWaiting(true);
  //       //navigator.serviceWorker.postMessage({ type: 'SKIP_WAITING' }); 
  //     }
  //   }
  // }, [swListener]);
  useEffect(() =>
  {    
    window.addEventListener("offline", onNetEvent);
    window.addEventListener("online", onNetEvent);    

    fingerprint2.get(function(components) {
      let values = components.map(function(component) {
        if (component.key === "platform") {
          localStorage.setItem("platform", component.value);
        }
        return component.value;
      });
      let murmur = fingerprint2.x64hash128(values.join(""), 31);
      localStorage.setItem("deviceId", murmur);
    });
    
    if (process.env.NODE_ENV !== "development") {
      // let listener = new ServiceWorkerUpdateListener();
      // setSwListener(listener);
      // listener.onupdateinstalling = (installingEvent) =>
      // {
      //   if (swIntervalIdRef.current!=null) {
      //     clearInterval(swIntervalIdRef.current);
      //   }
      //   console.log("SW installed", installingEvent);
      // };
      // listener.onupdatewaiting = (waitingEvent) =>
      // {
      //   console.log("new update waiting", waitingEvent);
      //   setUpdateWaiting(true);
      //   //handleUpdate();
      // };
      // listener.onupdateready = (event) =>
      // {
      //   console.log("updateready event");
      //   // window.location.reload();
      // };
      
      navigator.serviceWorker.getRegistration().then((reg) =>
      {
        registrationRef.current = reg;          
        reg.addEventListener("updatefound", () =>
        {
          const installingWorker = reg.installing;
          //const waitingWorker = reg.waiting;
          console.log("updatefound A new service worker is being installed:", installingWorker);
          if (installingWorker) {
            console.log("updatefound - installing worker setupdatewaiting");
            //setSwListener(installingWorker);
            setUpdateWaiting(true);
          }
        });

        reg.addEventListener("onstatechange", () =>
        {
          console.log("app.js onstatechange reg",reg);
          if (reg.installing) {
            console.log("app.js onstatechange reg.installing", reg);
            reg.installing.addEventListener("statechange", () =>
            {
              if (reg.waiting) {
                console.log("app.js onstatechange statechange waiting");
                //setSwListener(reg);
                setUpdateWaiting(true);
              }
            });
          }
          if (reg.waiting) {
            console.log("app.js onstatechange reg.waiting", reg);
            reg.waiting.addEventListener("statechange", () =>
            {
              //if (reg.waiting) {
                console.log("app.js onstatechange statechange waiting state=" + reg.state, reg);
                //setSwListener(reg);
                setUpdateWaiting(true);
             // }
            });
            const waitingWorker = reg.waiting;
            console.log("onstatechange A new service worker is waiting:", waitingWorker);
          }         
        });
       // setSwListener(registrationRef.current);
        swIntervalIdRef.current = setInterval(function ()
        {
          let d = new Date();
          let strStamp = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate() + " " + d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();
          console.log("timer update sw " + strStamp);           
          registrationRef.current.update();          
          //setSwListener(registrationRef.current);
        }, 10000 * 6);
      });
      return () =>
      {
        //listener.removeEventListener();
        window.removeEventListener("offline", onNetEvent);
        window.removeEventListener("online", onNetEvent);
      };
    } else {
      return () =>
      {
        window.removeEventListener("offline", onNetEvent);
        window.removeEventListener("online", onNetEvent);
      };
    }    
  }, []);
  const handleUpdate = () =>
  {
    console.log("app.js handleUpdate called updateWaiting:", updateWaiting);   
  }
  return (
    !loading && (      
      <>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={!networkAccess}
          onClose={() => {
            setNetworkAccess(false);
          }}
          sx={{ boxShadow: "0 0 12px rgb(0 0 0 / 70%)" }}
        >
          <Alert
            sx={{ width: "100%" }}
            severity="warning"
            variant="filled"
            action={
              <Button
                variant="outlined"
                onClick={onNetEvent}
                color="inherit"
                size="small"
              >
                retry
              </Button>
            }
          >
            <AlertTitle>
              Your device is offline, ensure your device has WIFI or Cellular
              coverage and mobile data is enabled
            </AlertTitle>
            this message will hide when device is online again
          </Alert>
        </Snackbar>       
        <ScreenLoading />
        {auth && auth.userProfile && auth.userProfile.driver_enroll === "1" && (
          <DriverAlarmsComp />
        )}
        <MainRouter />
        <UpdateWaiting updateWaiting={updateWaiting} handleUpdate={handleUpdate} />        
      </>
    ) 
  );
} //app

export default App;
