import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import useAuth from "../../hooks/useAuth"


const RequireAuth = () => {
    const {auth} = useAuth();
    const location = useLocation();

    return (//&& Object.keys(auth.userProfile).length > 1
            auth.accountCode && (auth.userProfile ) ? <Outlet /> : <Navigate to="/login" state={{ from: location }} replace />

    );
}

export default RequireAuth;