import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  DialogActions,
  DialogContent,
  FormControlLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import React, { useState } from "react";

function AckAlarmComp(props) {
  const [ackReason, setAckReason] = useState("");
  const [ackDuration, setAckDuration] = useState("");
  const [validation, setValidation] = useState({
    timeStamp: true,
    reason: true,
  });

  const handleAckClick = () => {
    let valid = true;
    let validationObj = {
      timeStamp: true,
      reason: true,
    };

    if (!ackDuration || ackDuration === "") {
      validationObj.timeStamp = false;
      valid = false;
    }

    if (!ackReason || ackReason.length < 7) {
      validationObj.reason = false;
      valid = false;
    }

    setValidation(validationObj);

    if (valid) {
      props.handleAckTimeSelect(ackDuration, ackReason, props.AlarmId);
    }
  };

  return (
    <>
      <DialogContent>
        <RadioGroup
          aria-labelledby="radio-buttons-group-label"
          value={ackDuration}
          onChange={(e) => {
            setAckDuration(e.target.value);
          }}
          name="radio-buttons-group"
        >
          {[2, 6, 12, 24, 48].map((timestamp) => (
            <FormControlLabel
              key={timestamp}
              value={timestamp}
              control={<Radio />}
              label={timestamp + " Hours"}
            />
          ))}
          {!validation.timeStamp && (
            <FormControlLabel
              control={
                <div
                  style={{
                    color: "#d32f2f",
                    paddingLeft: "14px",
                    fontSize: "12px",
                  }}
                >
                  {"Please select time for acknowledge"}
                </div>
              }
            />
          )}
        </RadioGroup>
        <br />
        <TextField
          fullWidth
          required
          error={!validation.reason}
          helperText={
            !validation.reason && "Reason must be at least 7 characters long"
          }
          label="Ack Reason"
          variant="outlined"
          value={ackReason}
          size="small"
          onChange={(e) => {
            setAckReason(e.target.value);
          }}
        />
      </DialogContent>

      <DialogActions sx={{ alignSelf: "center", marginBottom: "10px" }}>
        <Button variant="outlined" onClick={handleAckClick}>
          Acknowledge
        </Button>
      </DialogActions>
    </>
  );
}

export default AckAlarmComp;
