import React, { createContext, useState, useEffect,useMemo } from "react";
//import useAxiosPrivate from "../hooks/useAxiosPrivate";
const AuthContext = createContext({});
//const axiosPrivate = useAxiosPrivate();
export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({});
  // const changeUserProfileMiniTrackingBox = (value) => {
  //   if (value!=null) {
  //     setAuth((prev) => {
  //       return {
  //         ...prev,
  //         userProfile: {
  //           ...prev.userProfile,
  //           web_settings: { "miniTrackingBox": value ? "true" : "false" },
  //         },
  //       };
  //     });        
  //   } 
  // };
  // const miniTrackingInfoBoxSetting = useMemo(() => {
  //   console.log("AuthProvider miniTrackingInfoBoxSetting memo change", auth.userProfile);
  //   if (auth && auth.userProfile && auth.userProfile.hasOwnProperty("web_settings") && auth.userProfile["web_settings"].hasOwnProperty("miniTrackingBox")) {
  //     //console.log("has setting : miniTrackingInfoBoxSetting", Boolean(auth.userProfile.web_settings["miniTrackingBox"] == "true"));
  //     return Boolean(auth.userProfile.web_settings["miniTrackingBox"] === "true");
  //   } return false;
  // }, [auth && auth.userProfile]);
  useEffect(() => {
    console.log("AuthProvider auth change", auth, auth.userProfile);
  }, [auth]);
  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
