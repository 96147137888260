import { LoadingButton } from "@mui/lab";
import {
  TextField,
  Box,
  Alert,
  Collapse,
  InputAdornment,
  IconButton,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "../../api/axios";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useAuth from "../../hooks/useAuth";
import CryptoJS from "crypto-js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import appSettings from "../../../package.json";

function LoginComp() {
  const axiosPrivate = useAxiosPrivate();
  const { setAuth } = useAuth();
  const [account, setAccount] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [validP, setValidP] = useState(true);
  const [validA, setValidA] = useState(true);
  const [loadB, setLoadB] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [uData, setUData] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const from = (location.state && location.state.from && location.state.from.pathname) || "/main";

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const encryptCredentials = () => {
    let regpatt = /[^A-Za-z0-9+/=\s]/g;
    let res =
      localStorage.getItem("deviceId") &&
      localStorage.getItem("deviceId").replace(regpatt, "");

    if (res && res.trim().length < 16) {
      res = res.padStart(16, "0");
    } else if (res && res.trim().length > 16) {
      res = res.substring(0, 16);
    }
    let key = CryptoJS.enc.Utf8.parse(res);
    let iv = CryptoJS.enc.Utf8.parse(res);

    let encrypted = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(
        JSON.stringify({ UserName: account, Password: password })
      ),
      key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );
    return encrypted;
  };

  const submit = () => {
    if (password !== "" && account !== "") {
      setLoadB(true);
      let encCredentials = encryptCredentials();
      axios.post("/auth/AuthorizeUser/", encodeURIComponent(encCredentials.toString()),
          {
            withCredentials: true,
            headers: {
              "Content-Type": "application/json",
              deviceId: localStorage.getItem("deviceId"),
            },
          }
        )
        .then(function (response) {
          console.log(response);
          setErrorMsg("");
          const accessToken = response.data.accessToken;
          const accountCode = response.data.clientIdentifier;
          const userData = response.data;
          console.log("authorizeuser response", response.data);
          localStorage.setItem("driverPoiSettings", "Not Set");
          setAuth({ accountCode, accessToken, userData });
          setUData(response.data );
          // setLoadB(false);
          // navigate(from, { replace: true });
        })
        .catch(function (error) {
          setAuth({});
          if (error.response.status === 401) {
            setErrorMsg(error.response.data.message || "Account or Password incorrect");
          }
          console.error(error);
          setLoadB(false);
        })
        .finally(function () {
          //setLoadB(false);
        });
    } else {
      setValidP(password !== "");
      setValidA(account !== "");
    }
  };
  const getUserProfile = async (ud) => {
    console.log("getUserProfile start ud", ud);
    if (ud && (ud.userRight == "V" || ud.userRight == "F")) {
      setAuth((prev) => {
        return {
          ...prev,
          userProfile: { },
          driverPoiSettings: localStorage.getItem("driverPoiSettings")
        };
      });     
      setLoadB(false);
      navigate(from, { replace: true });
      return;
    } 
    if (ud && (ud.userRight == "UL")) {
      setAuth((prev) => {
        return {
          ...prev,
          userProfile: {},
          driverPoiSettings: localStorage.getItem("driverPoiSettings")
        };
      });
      setLoadB(false);
      navigate(from, { replace: true });
      return;
    }
    //console.log("getUserProfile axios");
    let response = await axiosPrivate.get("/Account/GetUserProfile/" + (ud && ud.clientId));
    //console.log("getUserProfile axios response", response);
    if (response != null) {
      let obj = response.data;
      if (typeof response.data === "string") {
        obj = (JSON.parse(response.data)) || {}
      }
      console.log("getUserProfile response obj", obj);
      if (obj != null) {
        // if (obj.hasOwnProperty("web_settings") && obj.hasOwnProperty("miniTrackingBox")) {
        //   changeUserProfileMiniTrackingBox(obj.web_settings.miniTrackingBox === "true" ? true : false);
        // }
        
        setAuth((prev) => {
          return {
            ...prev,
            userProfile: { ...prev.userProfile, ...obj },            
            driverPoiSettings: localStorage.getItem("driverPoiSettings")
          };
        });
        // if (obj.hasOwnProperty("web_settings") && obj.web_settings.hasOwnProperty("miniTrackingBox") && obj.web_settings.miniTrackingBox != "") {
        //   console.log("getUserProfile obj.websettings=", obj.web_settings, obj.web_settings.miniTrackingBox === "true" ? true : false);
        //   setEnableMiniTrackingBox(true); //obj.web_settings.miniTrackingBox === "true" ? true : false
        // }
        //getPoiList(response.data.driver_enroll);
        setLoadB(false);
        navigate(from, { replace: true });
      }
    } else {
      console.log("getUserProfile bad response", response);
      setErrorMsg("Error getting User Profile, please retry");
    }
  };

  React.useEffect(() => {
    sessionStorage.setItem("video_data", null);
    localStorage.setItem("driverPoiSettings", "Not Set");
    setAuth({});
  }, []);
  React.useEffect(() => {   
      console.log("userData changed", uData);
      uData && getUserProfile(uData);   
  }, [uData]);
  return (    
    <div style={{ height: "100%" }}>
      <Box p="5%">
        <img
          src={"images/logo.png"}
          alt="logo"
          style={{ height: "70px" }}
        ></img>
        <Box fontSize="20px" m="20px">
          Welcome, Please login to your account
        </Box>
        <Box
          sx={{
            "& .MuiTextField-root": { m: "10px", width: "65ch" },
          }}
        >
          <TextField
            required
            size="small"
            error={!validA}
            label="Account"
            helperText={validA ? "" : "Account code is required"}
            onChange={(e) => setAccount(e.target.value)}
            onKeyUp={(e) => {
              if (e.key === "Enter" || e.key === "enter") {
                submit();
              }
            }}
          />
          <br />
          <TextField
            required
            size="small"
            error={!validP}
            type={showPassword ? "text" : "password"}
            value={password}
            label="Password"
            helperText={validP ? "" : "Password is required"}
            onChange={(e) => setPassword(e.target.value)}
            onKeyUp={(e) => {
              if (e.key === "Enter" || e.key === "enter") {
                submit();
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    size="small"
                    // onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? (
                      <FontAwesomeIcon icon="eye-slash" />
                    ) : (
                      <FontAwesomeIcon icon="eye" />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <LoadingButton
          size="small"
          loading={loadB}
          variant="contained"
          sx={{ borderRadius: "20px", width: "30ch", m: "20px" }}
          onClick={submit}
        >
          Log In
        </LoadingButton>

        <Collapse in={errorMsg !== ""}>
          <Alert severity="error">{errorMsg}</Alert>
        </Collapse>
      </Box>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>{`Version: ${appSettings.version}`}</div>
    </div>
  );
}

export default LoginComp;
