import { Backdrop, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import useRefreshToken from "../../hooks/useRefreshToken";

const PersistLogin = () => {
  const [isLoading, setIsLoading] = useState(true);
  const refresh = useRefreshToken();
  const { auth } = useAuth();

  useEffect(() => {
    let isMounted = true;
    const verifyRefreshToken = async () => {
      try {
        await refresh();
      } catch (error) {
        console.error(error);
      } finally {
        isMounted && setIsLoading(false);
      }
    };

    !auth.accessToken ? verifyRefreshToken() : setIsLoading(false);

    return () => (isMounted = false);
  }, []);

  useEffect(() => {
    // console.log("isLoading: " + isLoading);
    // console.log("aT: " + JSON.stringify(auth));
  }, [isLoading]);

  return (
    <>
      {isLoading ? (
        <div className="container" style={{ backgroundColor: "#1c374f" }}>
          <div className="center">
            <img style={{ width: "200px" }} src="images/Altrack-icon-large 600.gif" />
          </div>
        </div>
      ) : (
        // <Backdrop
        //   sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        //   open={isLoading}
        // >
        //   <CircularProgress color="inherit" />
        //   Loading...
        // </Backdrop>
        // <div>loading...</div>
        <Outlet />
      )}
    </>
  );
};

export default PersistLogin;
