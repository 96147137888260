import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { library } from "@fortawesome/fontawesome-svg-core";
import * as FontAwesomeIcons from "@fortawesome/free-solid-svg-icons";

import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./context/authProvider";
import { createTheme, ThemeProvider } from "@mui/material";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
//import * as serviceWorker from './service-worker';

Object.keys(FontAwesomeIcons).forEach((key) => {
  try {
    library.add(FontAwesomeIcons[key]);
  } catch (error) {}
});

const theme = createTheme({
  palette: {
    primary: {
      main: "#1c374f",
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </AuthProvider>
  </BrowserRouter>
  // </React.StrictMode>
);
// const configuration = {
//   onUpdate: (registration) =>
//   {
//     if (registration && registration.waiting) {
//       console.log("configuration registration.waiting auto skipwait");
//       //if (window.confirm('New version available!  refresh to update your app?')) {
//         registration.waiting.postMessage({ type: 'SKIP_WAITING' });
//        // window.location.reload();
//       //}
//     }
//   }
// };
serviceWorkerRegistration.register();
//serviceWorker.register('/sw.js');
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
