import React, { useEffect, useState } from "react";
import { register } from "../../serviceWorkerRegistration";
import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

let serviceWorker;

export default function ScreenLoading() {
  const [showUpdate, setShowUpdate] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  function onSWUpdate(registration) {
    setShowUpdate(true);
    serviceWorker = registration.waiting;
  }

  function onSWSuccess() {
    console.log("App installed as a PWA.");
  }

  function updateSW() {
    setShowLoading(true);
    if (!serviceWorker) return;
    // Add listener for state change of service worker
    serviceWorker.onstatechange = () => {
      if (
        serviceWorker &&
        serviceWorker.state === "activated" &&
        navigator.serviceWorker.controller
      ) {
        // Reload page if waiting was successfully skipped
        window.location.reload();
      }
    };
    serviceWorker.postMessage({ type: "SKIP_WAITING" });
    setShowUpdate(false);
  }

  useEffect(() => {
    if ("serviceWorker" in navigator && "register" in navigator.serviceWorker) {
      register({
        onUpdate: onSWUpdate,
        onSuccess: onSWSuccess,
      });
    }
  }, []);

  return (
    <div>
      <Dialog open={showUpdate}>
        <DialogTitle>Update available</DialogTitle>
        <DialogContent>An update has been found. Please refresh.</DialogContent>
        <DialogActions>
          <Button autoFocus onClick={updateSW}>
            Update
          </Button>
        </DialogActions>
      </Dialog>

      {showLoading && (
        <div>
          <Backdrop
            // sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={showLoading}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </div>
  );
}
