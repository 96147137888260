import React, { lazy, Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import LoginComp from "../components/login/login";
import PersistLogin from "../components/persist-login/persistLogin";
import RequireAuth from "../components/require-auth/requireAuth";

const DriverManagementMainComp = lazy(() =>
  import(
    "../components/driver-management/driver-management-main/driverManagement"
  )
);
const WrapperComp = lazy(() =>
  import("../components/wrapper-component/wrapperComponent")
);

function MainRouter() {
  return (
    <Suspense
      // fallback={
      //   // <Backdrop
      //   //   sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      //   //   open
      //   // >
      //   //   <CircularProgress color="inherit" />
      //   //   Loading...
      //   // </Backdrop>
        
        
      //   <div className="container" style={{ backgroundColor: "#1c374f" }}>
      //     <div className="center">
      //       <img style={{ width: "200px" }} src="images/logo.png" />
      //     </div>
      //   </div>
      // }
    >
      {/* style={{ display: "flex", flexFlow: "column" }} */}
      <div className="App" >
        <Routes>
          <Route element={<PersistLogin />}>
            <Route element={<RequireAuth />}>
              
              <Route path="/main" element={<WrapperComp />} />
              <Route path="/driver-management" element={<DriverManagementMainComp />} />
              
            </Route>
          </Route>
          <Route path="/login" element={<LoginComp />} />

          <Route path="*" element={<Navigate to="/main" />} />

          {/* <Route path="/login/:id" element={<LoginComp />} /> in case we need param to pass */}
          {/*

in order to read the params in destination component:
import {useParams} from "react-router-dom"
const params = useParams()

it reads the params from the url

ID = {parapm.id}
*/}
        </Routes>
      </div>
    </Suspense>
  );
}

export default MainRouter;
