import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Dialog,
  DialogTitle,
  Divider,
  IconButton,
  Paper,
  Stack,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import AckAlarmComp from "../ack-alarm/ackAlarm";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "darkorange",
  color: "white",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  // color: theme.palette.text.secondary,
}));

function DriverAlarmsComp() {
  const [driverAlarms, setDriverAlarms] = useState([]);
  const [openAlarmAckDialog, setOpenAlarmAckDialog] = useState({
    open: false,
  });

  const axiosPrivate = useAxiosPrivate();

  const getDriverAlarms = () => {
    axiosPrivate
      .get("/DriverAlarms/GetDriverAlarms")
      .then(function (response) {
        console.log(response);
        if (typeof response.data === "object") {
          setDriverAlarms(response.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    // axiosPrivate
    //   .post(
    //     "/DriverAlarms/AddNewAlarm/1100553196/testInsert/driverUsername Test"
    //   )
    //   .then(function(response) {
    //     console.log(response);
    //   })
    //   .catch(function(error) {
    //     console.log(error);
    //   });
  };

  const handleDriverAlertSnoozeClick = (alarmId) => {
    // trigger_stamp to S and the ack_stamp to NOW + 30 minutes
    axiosPrivate
      .post("/DriverAlarms/EditAlarmStatus", {
        AckStatus: "S",
        AlarmID: alarmId,
      })
      .then(function (response) {
        console.log(response);
        if (response.status === 200) {
          setDriverAlarms(
            driverAlarms.filter((alarm) => alarm.AlarmID !== alarmId)
          );
        }
      });
  };

  const handleAckTimeSelect = (timestamp, ackReason, alarmId) => {
    //If they press the Ack (acknowledge) button, they must be asked to specify the number of hours from a list and provide a reason which will be at least 7 characters long, it must set ack_flag, to A, ack stamp to NOW + number of hours, ack_user to the current logged in user (for now the account code), the ack_reason.

    console.log(timestamp, ackReason, alarmId);
    axiosPrivate
      .post("/DriverAlarms/EditAlarmStatus", {
        AckStatus: "A",
        AlarmID: alarmId,
        AcknowledgeDuration: timestamp,
        AckReason: ackReason,
      })
      .then(function (response) {
        console.log(response);
        if (response.status === 200) {
          setOpenAlarmAckDialog({ open: false });
          setDriverAlarms(
            driverAlarms.filter((alarm) => alarm.AlarmID !== alarmId)
          );
        }
      });
  };

  useEffect(() => {
    getDriverAlarms();
    let alarmInterval = setInterval(function () {
      getDriverAlarms();
    }, 60000 * 30);

    return () => {
      if (alarmInterval) {
        clearInterval(alarmInterval);
      }
    };
  }, []);
  return (
    <div>
      {driverAlarms && driverAlarms.length > 0 && (
        <div
          style={{
            zIndex: 1000, //consider 1055 to cover driver management 'add new driver' button
            position: "fixed",
            right: "20px",
            bottom: "20px",
            height: "calc(100% - 160px)",
            overflow: "hidden",
          }}
        >
          <Stack spacing={2}>
            {driverAlarms.map((alarm, index) => {
              return (
                <Item
                  key={index}
                  sx={{
                    width: "250px",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "16px",
                  }}
                >
                  <FontAwesomeIcon icon="bell" size="lg" />
                  <Divider
                    sx={{ margin: "0 5px", borderColor: "white" }}
                    orientation="vertical"
                    flexItem
                  />
                  <div style={{ flex: 1 }}>
                    <div
                      style={{
                        display: "flex",
                        gap: "7px",
                        alignItems: "center",
                      }}
                    >
                      <Tooltip
                        title={
                          alarm.Display
                            ? alarm.Display +
                              (alarm.DriverUsername
                                ? ", Driver: " + alarm.DriverUsername
                                : "")
                            : alarm.DriverUsername
                            ? "Driver: " + alarm.DriverUsername
                            : ""
                        }
                      >
                        <span
                          className="text-long"
                          style={{ maxWidth: "140px" }}
                        >
                          {alarm.Display
                            ? alarm.Display +
                              (alarm.DriverUsername
                                ? ", Driver: " + alarm.DriverUsername
                                : "")
                            : alarm.DriverUsername
                            ? "Driver: " + alarm.DriverUsername
                            : ""}
                        </span>
                      </Tooltip>
                      <div style={{ marginLeft: "auto" }}>
                        <Tooltip title="Snooze">
                          <IconButton
                            color="inherit"
                            size="small"
                            onClick={() =>
                              handleDriverAlertSnoozeClick(alarm.AlarmID)
                            }
                          >
                            <FontAwesomeIcon icon="clock" size="sm" />
                          </IconButton>
                        </Tooltip>
                        <span>/</span>
                        <Tooltip title="Acknowledge">
                          <IconButton
                            color="inherit"
                            size="small"
                            onClick={() =>
                              setOpenAlarmAckDialog({
                                open: true,
                                id: alarm.AlarmID,
                              })
                            }
                          >
                            <FontAwesomeIcon icon="circle-check" size="sm" />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                    <Tooltip title={alarm.AlarmType}>
                      <span
                        className="text-long"
                        style={{
                          float: "left",
                          fontSize: "12px",
                          maxWidth: "200px",
                        }}
                      >
                        {alarm.AlarmType}
                      </span>
                    </Tooltip>
                  </div>
                </Item>
              );
            })}
          </Stack>
        </div>
      )}

      <Dialog
        open={
          openAlarmAckDialog && openAlarmAckDialog.id
            ? openAlarmAckDialog.open
            : false
        }
      >
        <DialogTitle>Select time and reason for acknowledge</DialogTitle>
        <AckAlarmComp
          AlarmId={openAlarmAckDialog.id}
          handleAckTimeSelect={handleAckTimeSelect}
        />
      </Dialog>
    </div>
  );
}

export default DriverAlarmsComp;
